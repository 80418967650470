import React from "react";
import bannerImg from '../images/menfashion.jpg';
import bannerImgMobile from '../images/2ndmenfashion.jpg';
import { Grid, CardMedia, CardContent, Typography, Button, useMediaQuery } from '@mui/material';
import "../styles/allstyles.css";

import { Global, css } from "@emotion/react";
import { createGlobalStyle } from 'styled-components';



const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Outfit Variable', sans-serif;
  }
`;

const Home = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Example for mobile screen width



  return (
    <>


      <div className="hero border-1 pb-3" >
        <div className="card  text-white border-0 mx-3">
          <div>
            {isMobile ? (
              <img

                src={bannerImgMobile}
                alt="Mobile Image"
                className="responsiveImage"
              />
            ) : (
              <img
                src={bannerImg}
                alt="Desktop Image"
                className="responsiveImage"
              />
            )}
          </div>
          {/* <div className="card-img-overlay d-flex align-items-center">
            <div className="container">
              <h5 className="card-title fs-1 text fw-lighter" style={{fontWeight:"bold"}}>BEST PRODUCT FROM AMAZONE</h5>
              <p className="card-text fs-5 d-none d-sm-block ">
                This is a wider card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;
