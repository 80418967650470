export const productData = [
  {
    id: "M33",
    title: "Flutter Developer Tshirt For Men | Cotton",
    productLink: "https://www.instagram.com/p/C11BKe3oAFx/",
    off: 47,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr43.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M13",
    title: "Cruze French Terry Cotton Hoodies ",
    productLink: "https://amzn.to/48cHcV0",

    off: 15,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr29.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  // Men Developer Tshirt addon

  {
    id: "M14",
    title: "Cruze French Terry Cotton Zip-Up Jacket ",
    productLink: "https://amzn.to/3GY6Wsp",

    off: 15,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr30.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M31",
    title: "NodeJs Developer Tshirt For Men | Cotton",
    productLink: "https://www.instagram.com/p/C1wkBf7P7BA/",
    off: 42,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr41.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M32",
    title: "AWS Developer Tshirt For Men | Cotton",
    productLink: "https://www.instagram.com/p/C1yaDTwPcCz/",
    off: 47,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr42.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M34",
    title: "Next.js Developer Tshirt For Men | Cotton",
    productLink: "https://www.instagram.com/p/C108IAhPlGQ/",
    off: 47,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr44.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M10",
    title: "ReactJs Tshirt for Men | Black Color Cotton Tshirt",
    productLink: "https://www.instagram.com/p/C1jFBMto8kd/",

    off: 25,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr40.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M12",
    title:
      "Full Sleeve Cotton Hooded Sweatshirt Front and Back Graphic Printed ",
    productLink: "https://amzn.to/3RXa7H6",

    off: 67,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr28.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M11",
    title: "Men's Cotton Hooded Sweatshirt with Zip",
    productLink: "https://amzn.to/3RFzWdi",

    off: 76,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr27.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M15",
    title: "Lymio Hoodies | Sweatshirt for Unisex | Unisex Hoodie",
    productLink: "https://amzn.to/3GYT8On",

    off: 88,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr25.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M16",
    title:
      "Cotton Poly Fleece Oversized Fit Full Sleeves| Solid Sweatshirt for Men",
    productLink: "https://amzn.to/47dRfYP",

    off: 71,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr26.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M17",
    title: "Men's Travel Cotton Solid Plain Hooded Sweatshirts ",
    productLink: "https://amzn.to/4asJkJU",

    off: 40,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr31.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M18",
    title: "Amazon Brand - Symbol Men Hooded Sweatshirt",
    productLink: "https://amzn.to/41CF9Hu",

    off: 69,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr32.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M19",
    title:
      "Popster Printed Hooded Sweatshirt Hoody Oversize Loose Fit Sweatshirts Winter Wear",
    productLink: "https://amzn.to/3RZsVFR",

    off: 60,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr34.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F10",
    title:
      "PDKFASHIONS Women Sweatshirt with Hoodies, Full Sleeves Women Winter Wear",
    productLink: "https://amzn.to/3RC4vRm",

    off: 35,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr35.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F11",
    title: "Amazon Brand - Women's Cotton Blend Hooded Neck Hoodies      ",
    productLink: "https://amzn.to/3GZ6V7x",

    off: 59,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr36.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F12",
    title:
      "Sweatshirts Winter Wear for Women | Hoody Regular Fit Full Sleeves Stylish Jumper ",
    productLink: "https://amzn.to/3RXdm17",

    off: 60,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr37.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F13",
    title: "Stylish Plain Green Long Sleeve Cotton Tshirt for Women (Green)  ",
    productLink: "https://amzn.to/3O52VGL",

    off: 66,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr33.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M20",
    title: "ENDEAVOUR WEAR Men Fleece Crew Neck Sweatshirt",
    productLink: "https://amzn.to/3TEu5aK",

    off: 65,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr38.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F14",
    title: "Tom & Jerry: White Color Women Oversized Sweatshirts",
    productLink: "https://amzn.to/47bmOlT",

    off: 42,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr39.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M21",
    title: "What's Up Doc Sage Green Printed Oversized T-Shirts",
    productLink: "https://amzn.to/3RXAyLN",

    off: 37,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr19.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M22",
    title: "Souled Store: Over Thunder Grey Printed Oversized T-Shirts",
    productLink: "https://amzn.to/47dH8mO",

    off: 44,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr18.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M23",
    title: "Men's Relaxed Fit Hooded Sweatshirt",
    productLink: "https://amzn.to/3RXDHv5",

    off: 30,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr24.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "M24",
    title: "Men's Cotton Hooded Neck Hoodies",
    productLink: "https://amzn.to/3GZhc3O",

    off: 67,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr17.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M25",
    title: "Men's Cotton Blend Round Neck Sweatshirt",
    productLink: "https://amzn.to/41BO3F6",

    off: 84,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr15.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M26",
    title: "Men's Solid Cotton Blend Hooded Neck Full Sleeves Sweatshirt",
    productLink: "https://amzn.to/3NIAHRF",

    off: 79,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr16.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M27",
    title:
      "The Souled Store: ICY Blue Colour Block Mens and Boys Oversized Sweatshirts",
    productLink: "https://amzn.to/4aAB4rd",

    off: 27,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr6.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M28",
    title:
      "The Souled Store: Official Looney Tunes: Bugs Special Mens and Boys Hoodies",
    productLink: "https://amzn.to/3RWpxLz",

    off: 46,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr7.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M29",
    title: "The Souled Store: Busted Mens and Boys Sweatshirts",
    productLink: "https://amzn.to/3tCRNJS",

    off: 33,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr8.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "M30",
    title:
      "Sweatshirt for Men Solids: Pastel Blue Colour Block Mens and Boys Sweatshirts",
    productLink: "https://amzn.to/41JxNlG",

    off: 23,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "men's clothing",
    image: require("./images/productsImg/pr9.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F15",
    title: "100% Cotton Green Color Women Oversized Cropped T-Shirts",
    productLink: "https://amzn.to/3GYfMGI",

    off: 58,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr12.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F16",
    title: "Women Multicolored Solid Sweatshirts ",
    productLink: "https://amzn.to/48zAQ1J",

    off: 58,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr13.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F17",
    title:
      "Kung Fu Panda: Women and Girl Oversized Cropped T-Shirts|100% Cotton|Half Sleeve",
    productLink: "https://amzn.to/47e1jAT",

    off: 72,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr14.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F18",
    title:
      "Women Sweatshirts |Trippin' Women and Girls Sweatshirts|100% Cotton Green Color ",
    productLink: "https://amzn.to/3NHm55k",

    off: 62,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr20.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F19",
    title: "Souled Store Women Citrus Solid Women Sweatshirts",
    productLink: "https://amzn.to/4aDDxRO",

    off: 42,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr21.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F20",
    title: "Women Oversized Sweatshirts | Full Sleeve| Loose fit",
    productLink: "https://amzn.to/3tCZyQ0",

    off: 20,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr22.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F21",
    title: "Women Oversized Sweatshirts |Full Sleeve| Multicolored Color ",
    productLink: "https://amzn.to/4aOoBkb",

    off: 58,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr23.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F22",
    title:
      "Characters Mosaic Women and Girls Regular fit Graphic Printed Half Sleeve ",
    productLink: "https://amzn.to/4auuAdL",

    off: 37,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr10.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F23",
    title: "The Lion King: Yellow Oversized T-Shirts",
    productLink: "https://amzn.to/3RFVZ3K",

    off: 25,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "women's clothing",
    image: require("./images/productsImg/pr11.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  // Tote Bags Addon
  {
    id: "F25",
    title: "Doodle Women Black Cotton Tote Bag For College",
    productLink:
      "https://www.flipkart.com/doodle-women-black-tote/p/itm714b6e97df94d?pid=HMBGJNWJYPTHR7FB&lid=LSTHMBGJNWJYPTHR7FBCIA4XL&marketplace=FLIPKART&sattr[]=color&st=color&otracker=pp_reco_Similar%252BProducts_10_Doodle%252BWomen%252BBlack%252BTote_HMBGJNWJYPTHR7FB_productRecommendation%252Fsimilar_9",

    off: 46,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag2.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F24",
    title: "EcoRight Women Black Cotton Tote Bag For College",
    productLink:
      "https://www.flipkart.com/ecoright-women-black-tote/p/itme0a6e5b8f2941?pid=HMBGMMHCHN6Q3ZCC&lid=LSTHMBGMMHCHN6Q3ZCCMW37UM&marketplace=FLIPKART&sattr[]=color&st=color",

    off: 50,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag1.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F26",
    title: "EcoRight Women Black Cotton Tote Bag For College",
    productLink:
      "https://www.flipkart.com/ecoright-women-black-tote/p/itmacf32d7e7737e?pid=HMBGMMMVYJXNNUZT&lid=LSTHMBGMMMVYJXNNUZT2BBTO3&marketplace=FLIPKART&q=cotton+tote+bags+women&store=reh%2Fihu%2Fv57&srno=s_1_15&otracker=search&otracker1=search&fm=Search&iid=66939a14-abbb-4953-ad36-4c5244fbf336.HMBGMMMVYJXNNUZT.SEARCH&ppt=sp&ppn=sp&ssid=3s8300xa340000001704267428127&qH=1436a3df7a5450e0",

    off: 25,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag3.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },

  {
    id: "F27",
    title: "THE SACK CO Men & Women Black Tote | Cotton Tote Bag",
    productLink:
      "https://www.flipkart.com/sack-co-men-women-black-tote/p/itma80309ac4326a?pid=HMBGSC658PR83XD6&lid=LSTHMBGSC658PR83XD6XEC7SL&marketplace=FLIPKART&fm=productRecommendation%2Fsimilar&iid=R%3As%3Bp%3AHMBGSQXZGW3URPSM%3Bl%3ALSTHMBGSQXZGW3URPSMRGHTR0%3Bpt%3App%3Buid%3Ad5494719-aa32-11ee-8554-871eb857593f%3B.HMBGSC658PR83XD6&ppt=pp&ppn=pp&ssid=rts9v3139c0000001704284440863&otracker=pp_reco_Similar%2BProducts_24_THE%2BSACK%2BCO%2BMen%2B%2526%2BWomen%2BBlack%2BTote_HMBGSC658PR83XD6_productRecommendation%2Fsimilar_23&otracker1=pp_reco_NA_productRecommendation%2Fsimilar_Similar%2BProducts_DESKTOP_HORIZONTAL_productCard_cc_24_NA_view-all&cid=HMBGSC658PR83XD6",

    off: 30,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag5.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F28",
    title: "Torreto Women White Tote | Cotton Tote Bag",
    productLink:
      "https://www.flipkart.com/torreto-women-white-tote/p/itme2a418c0e79eb?pid=HMBGSQXZGW3URPSM&lid=LSTHMBGSQXZGW3URPSMRGHTR0&marketplace=FLIPKART&fm=productRecommendation%2Fsimilar&iid=R%3As%3Bp%3AHMBGKDGUPHZZAYZG%3Bl%3ALSTHMBGKDGUPHZZAYZGPQVFBK%3Bpt%3App%3Buid%3Abe67950a-aa32-11ee-8a88-816213ea40fc%3B.HMBGSQXZGW3URPSM&ppt=pp&ppn=pp&ssid=rts9v3139c0000001704284440863&otracker=pp_reco_Similar%2BProducts_1_33.productCard.PMU_HORIZONTAL_torreto%2BWomen%2BWhite%2BTote_HMBGSQXZGW3URPSM_productRecommendation%2Fsimilar_0&otracker1=pp_reco_PINNED_productRecommendation%2Fsimilar_Similar%2BProducts_GRID_productCard_cc_1_NA_view-all&cid=HMBGSQXZGW3URPSM",
    off: 45,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag6.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
  {
    id: "F29",
    title: "THE SACK CO Women White Tote | Cotton Tote Bag",
    productLink:
      "https://www.flipkart.com/sack-co-women-white-tote/p/itm8107882c82526?pid=HMBGDH5FXWVBZPKQ&lid=LSTHMBGDH5FXWVBZPKQTZEHL4&marketplace=FLIPKART&fm=productRecommendation%2Fsimilar&iid=R%3As%3Bp%3AHMBGDH52KBYPQ7KN%3Bl%3ALSTHMBGDH52KBYPQ7KNRQ1Y4P%3Bpt%3App%3Buid%3A9baee0ee-aa32-11ee-bd10-e9c7bfa7410b%3B.HMBGDH5FXWVBZPKQ&ppt=pp&ppn=pp&ssid=rts9v3139c0000001704284440863&otracker=pp_reco_Similar%2BProducts_1_33.productCard.PMU_HORIZONTAL_THE%2BSACK%2BCO%2BWomen%2BWhite%2BTote_HMBGDH5FXWVBZPKQ_productRecommendation%2Fsimilar_0&otracker1=pp_reco_PINNED_productRecommendation%2Fsimilar_Similar%2BProducts_GRID_productCard_cc_1_NA_view-all&cid=HMBGDH5FXWVBZPKQ",

    off: 40,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "tote bags",
    image: require("./images/productsImg/totebags/bag4.jpg"),
    rating: {
      rate: 4.6,
      count: 400,
    },
  },
];
