import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Grid, CardMedia, CardContent, Typography, Button, useMediaQuery } from '@mui/material';
import { productData } from '../productsData'

import amazonelogo from '../images/amazonelogo.png';
import bhajaamIcon from '../images/bhajaam_icon.png';

import "../styles/allstyles.css";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

// Supports weights 100-900
import '@fontsource-variable/outfit';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const Products = () => {
  const [data, setData] = useState(productData);
  const [filter, setFilter] = useState(data);
  const [filterData, setFilterData] = useState(data);
  const [search, setSearch] = useState('');
  const [filterBy, setFilterBy] = useState('All');
  const [loading, setLoading] = useState(false);
  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product))
  }


  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => cat === "All" || item.category === cat);
    setFilter(updatedList);
    setFilterData(updatedList);
    setFilterBy(cat);
    setSearch('');
  }

  const searchProduct = (value) => {
    setFilter(filterData.filter((item) => item.id.includes(value)));
    setSearch(value)
  }

  const ShowProducts = () => {
    const isMobile = useMediaQuery('(max-width:600px)'); // Example for mobile screen width

    return (
      <>
        <div>
          <div className="buttons text-center">
            <button className="btn btn-outline-dark btn-sm m-9" style={filterBy === "All" ? { backgroundColor: "black", color: "white" } : {}} onClick={() => filterProduct("All")}>All</button>
            <button className="btn btn-outline-dark btn-sm m-2" style={filterBy === "men's clothing" ? { backgroundColor: "black", color: "white" } : {}}  onClick={() => filterProduct("men's clothing") }>Men's Clothing</button>
            <button className="btn btn-outline-dark btn-sm m-1" style={filterBy === "women's clothing" ? { backgroundColor: "black", color: "white" } : {}} onClick={() => filterProduct("women's clothing")}>Women's Clothing</button>
           
            <button className="btn btn-outline-dark btn-sm m-1" style={filterBy === "tote bags" ? { backgroundColor: "black", color: "white" } : {}} onClick={() => filterProduct("tote bags")}>
            Tote Bags


            </button>

            

            {/* Other buttons */}
          </div>
          <div style={{ clear: 'both' }}></div> {/* Clear floating elements */}

          <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: "end", alignSelf: "end", alignItems: "end" }}>

            <div style={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '8px',



              width: isMobile ? '100%' : '85%',

              backgroundColor: "white", margin: '0 auto', marginBottom: 10
            }}>
              <TextField
                id="fullWidth"
                placeholder="Product Id Se Search Karo too milegaa..."
                variant="standard"
                InputLabelProps={{ shrink: false }}

                value={search}
                onChange={(e) => searchProduct(e.target.value)}
                InputProps={{
                  style: {
                    fontSize: isMobile ? '13px' : '16px',
                  }, // Change placeholder font size and color

                  disableUnderline: true,
                  endAdornment: <SearchIcon style={{
                    fontSize: 36, // Adjust the icon size here


                    backgroundColor: "#000", borderRadius: 1000, padding: 6
                    , marginLeft: '5px', color: '#fff', marginRight: 5
                  }} />, // Align SearchIcon to the left as startAdornment
                }}
                style={{ width: '100%', backgroundColor: 'inherit', marginLeft: 5, borderRadius: 'inherit', textAlign: 'center' }}
              />
            </div>
          </div>
        </div>

        {filter.map((product) => {
          return (

            <div className="cardStyle" >
              <Grid item xs={12} sm={6} md={4}  >
                <CardMedia
                  className="primgStyle"

                  style={{ borderRadius: 2, objectFit: "cover" }}

                  component="img"


                  alt="Sample Image 1"
                  image={product.image}
                />
                <CardContent style={{ paddingBottom: 15, paddingTop: 8 }}>
                  <Typography gutterBottom variant="h5" component="div" style={{
                    color: "#282c3f",
                    fontWeight: 500,
                    fontFamily: 'Outfit Variable',

                    height: isMobile ? '32px' : '22px',

                    fontSize: isMobile ? '13px' : '16px'

                    , overflow: "clip",
                  }}>
                    {product.title.substring(0, 35)} {product.title.substring(35) && "..."}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{

                    fontSize: isMobile ? '12px' : '14px'

                    , overflow: "hidden", paddingTop: 0,
                    fontFamily: 'Outfit Variable',
                  }}>
                    {/* {product.description.substring(0, 90)}{product.description.substring(90) && "..."} */}

                    {product.category}
                  </Typography>

                  <div style={{ marginBottom: 5 }}></div>
                  <Grid container style={{ justifyItems: "center", justifySelf: "center" }}>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary"

                        style={{
                          fontWeight: "bold", color: "#fa8900",
                          fontFamily: 'Outfit Variable',



                          fontSize: isMobile ? '12px' : '14px', // Change font size for mobile view

                        }}>
                        Up to {product.off}% OFF
                      </Typography>
                    </Grid>

                    <Grid item>

                      <div style={{ marginInline: 2 }}></div>
                    </Grid>



                    <Grid item>
                      <Typography variant="body2" color="text.secondary" style={{

                        marginTop: -2, marginBottom: 0,
                        fontFamily: 'Outfit Variable',

                        fontSize: isMobile ? '12px' : '16px', // Change font size for mobile view

                      }} >
                        |
                      </Typography>
                    </Grid>

                    <Grid item>

                      <div style={{ marginInline: 2 }}></div>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary" style={{
                        fontWeight: "bold",
                        fontFamily: 'Outfit Variable',
                        fontSize: isMobile ? '10px' : '14px',
                      }}>
                        {product.id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: 5 }}>

                  </div>


                  <Grid container spacing={2} justify="space-evenly" alignItems={"center"}>
                    <Grid item style={{minWidth: isMobile ? "10%" : "70%"}}>
                      <Button
                        href={product.productLink}
                        variant="contained"
                        className="responsiveButton"
                        style={{

                          fontFamily: 'Outfit Variable',
                          textTransform: "capitalize",
                          backgroundColor: "#FA8900",
                          fontWeight: 600,
                          width: '100%',
                          borderColor: "white",
                          fontSize: isMobile ? '13px' : '14px', // Change font size for mobile view
                          boxShadow: "none",
                          // Add other default styles as needed
                        }}


                        target="_blank"                 // Open link in new tab
                        rel="noopener noreferrer"

                        sx={{
                          '&:hover': {
                            color: '#fff !important', // Set the text color on hover (change #000 to your preferred color)
                          },
                        }}// Security best practices
                      >
                        {isMobile ? 'Shop now' : 'Shop now'}

                      </Button>
                    </Grid>
                    <Grid item>
                      <img src={product.productLink.includes('amzn') ? amazonelogo : bhajaamIcon} style={{

                        width: isMobile ? '20px' : '20px',
                        height: isMobile ? '20px' : '20px',
                      }} className="croppedImage" />

                    </Grid>

                  </Grid>
                </CardContent>
              </Grid>
            </div>
          );
        })}
      </>
    );
  };
  const isMobile = useMediaQuery('(max-width:600px)'); // Example for mobile screen width

  return (

    <>
      <div >
        <div className="row m-0 p-0" >
          <div className="col-12" >
            <h2 className="display-5 text-center" style={{ padding: 0, margin: 0 }}>New Launches</h2>
            <div style={{ textAlign: 'center', padding: 5, }}>
              {/* Horizontal rule centered within the container */}
              <hr style={{

                width: isMobile ? '100%' : '85%'
                , margin: 'auto',
              }} />
            </div>          </div>
        </div>
        <div className="row justify-content-center m-0 p-0">
          {loading ? <Loading /> : ShowProducts()}
        </div>
      </div>
    </>
  );
};

export default Products;
